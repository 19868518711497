<template>
  <div id="app">
    <div v-show="this.$route.name !== 'currency'" class="list">
      <div class="list-title">
        <span>Webtools - Online Web Utilities</span>
      </div>
      <div v-for="(item, index) in objArr" :key="item.id" class="tools-nav">
        <AdSense v-if="index === 0 || index === 2"></AdSense>
        <ToolsNav :c-item="item"></ToolsNav>
      </div>
    </div>
  </div>
</template>
<script>
import AdSense from '@/components/AdSense'
import ToolsNav from '@/components/ToolsNav'

export default {
  name: 'App',
  components: {
    AdSense,
    ToolsNav
  },
  data () {
    return {
      objArr: [
        {
          id: 8,
          image: require('../assets/img/currency.svg'),
          title: 'Currency Converter',
          info: 'Professional real-time online speed test',
          url: '#/currency',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 7,
          image: require('../assets/img/calculator.svg'),
          title: 'Online Calculator',
          info: 'Professional real-time online speed test',
          url: 'https://web.nextword.me/calculator/',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 1,
          image: require('../assets/img/icon_speed.svg'),
          title: 'Internet Speed Test Widget',
          info: 'Professional real-time online speed test',
          url: 'https://www.speedcheck.org/speedcheck-basic/',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 2,
          image: require('../assets/img/cutout.svg'),
          title: 'Image Remove Background',
          info: 'Professional real-time online speed test',
          url: 'https://www.cutout.pro/es/remove-background/upload',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 3,
          image: require('../assets/img/video.svg'),
          title: 'Convert Video',
          info: 'Professional real-time online speed test',
          url: 'https://cloudconvert.com/mp4-to-mp3',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 4,
          image: require('../assets/img/notes.svg'),
          title: 'Online Notes',
          info: 'Take notes online. "Free and no login" or "privacy and productivity"',
          url: 'https://www.rapidtables.com/tools/notes.html',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 5,
          image: require('../assets/img/screen.svg'),
          title: 'Online Screen Recorder',
          info: 'A free online screen recorder is one of the best tools',
          url: 'https://www.rapidtables.com/tools/screen-recorder.html',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 6,
          image: require('../assets/img/voice.svg'),
          title: 'Online Voice Recorder',
          info: 'Record audio from microphone to audio file',
          url: 'https://www.rapidtables.com/tools/voice-recorder.html',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 9,
          image: require('../assets/img/addtxt.svg'),
          title: 'Internet Speed Test Widget',
          info: 'Professional real-time online speed test',
          url: 'https://text2photo.com/app',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 10,
          image: require('../assets/img/clock.svg'),
          title: 'Alarm Clock',
          info: 'Set the hour and minute for the online alarm clock.',
          url: 'https://www.rapidtables.com/tools/alarm-clock.html',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 11,
          image: require('../assets/img/calendar.svg'),
          title: 'Calendar',
          info: 'Online and print friendly – for any year and month',
          url: 'https://www.rapidtables.com/tools/calendar.html',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 12,
          image: require('../assets/img/timer.svg'),
          title: 'Countdown Timer',
          info: 'The timer counts back hours,minutes and seconds.',
          url: 'https://www.rapidtables.com/tools/timer.html',
          browser: this.getRandom(1000, 5000)
        },
        {
          id: 13,
          image: require('../assets/img/random.svg'),
          title: 'Random Number Generator',
          info: 'Generate random numbers between min and max',
          url: 'https://www.rapidtables.com/tools/random-number-generator.html',
          browser: this.getRandom(1000, 5000)
        }
      ]
    }
  },
  methods: {
    // 获取随机数
    getRandom (start, end) {
      const num = end - start + 1
      return Math.floor(Math.random() * num + start)
    },
    goHome () {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style>
.list {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

.list-title {
  font-size: 22px;
  font-weight: bold;
  color: #004481;
  padding: 10px 0 16px 8px;
}
</style>
