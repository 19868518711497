<template>
  <div class="list-wrapper">
      <div class="list-wrapper-content" @click="navHandle()">
        <div class="content-img"><img :src="cItem.image" alt=""/></div>
        <div class="content-text">
          <span class="text-title">{{ cItem.title }}</span>
          <span class="text-info">{{ cItem.info }}</span>
        </div>
      </div>
    <span class="rating"></span>
    <span class="recommend">Recommend tools</span>
    <span class="browser">{{ cItem.browser }}</span>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data () {
    return {}
  },
  props: {
    cItem: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    navHandle() {
      // this.$router.push({
      //   path: '/currency'
      // })
      window.location.href = this.cItem.url
      console.log(this.$route.name)
    }
  }
}
</script>

<style scoped>
.list-wrapper {
  width: 100%;
  height: 135px;
  background-color: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
}

.list-wrapper-content {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  height: 60px;
  padding: 0 10px 0 22px;
  box-sizing: border-box;
}

.content-img {
  flex: 0 0 55px;
}

.content-text {
  flex: 1;
  padding-left: 15px;
}

.content-text .text-title {
  margin-top: 10px;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  float: left;
}

.content-text .text-info {
  margin-top: 10px;
  color: #b3b3b3;
  font-size: 14px;
  float: left;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.list-wrapper .rating {
  position: absolute;
  top: 10px;
  left: 15px;
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("../assets/img/rating.svg");
  background-repeat: no-repeat;
}

.list-wrapper .recommend {
  position: absolute;
  bottom: 10px;
  left: 16px;
  display: block;
  /*width: 16px;*/
  height: 14px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 16px;
  color: #b3b3b3;
  background-image: url("../assets/img/recommend.svg");
  background-repeat: no-repeat;
}

.list-wrapper .browser {
  position: absolute;
  top: 10px;
  right: 15px;
  display: block;
  height: 14px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 16px;
  color: #b3b3b3;
  background-image: url("../assets/img/browser.svg");
  background-repeat: no-repeat;
}

</style>
